import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../../app.constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class rewardsService {
  url: any;
  constructor(private location: Location, private http: HttpClient, private cookieService: CookieService) {
    this.url = CONFIGURATION.baseUrls.ServerPath;
  }

  GetUserRewards() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + 'Reward/GetRewardsHistory', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }

  GetRedeemHistory() {
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/x-www-form-urlencoded');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + 'Reward/GetRedeemHistory', {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token')
        })
    });
  }
  GetRewardData(accbal) {
    return this.http.post(this.url + 'Reward/GetRewardData?accbal=' + accbal, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
  InserrtReward(amount, catalougGuid, ipAddress) {
    return this.http.post(this.url + 'Reward/InserrtRewardRedeemprtions?amount=' + amount + '&catalougGuid=' + catalougGuid + '&ipAddress=' + ipAddress, {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + this.cookieService.get('token'),
        })
    });
  }
}
