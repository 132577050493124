import { Component, OnInit } from '@angular/core';
import { surveysService } from './../common/services/surveys.service';
import { rewardsService } from './../common/services/rewards.service';
import { Router, ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';
import { seoservice } from './../common/services/seo.service';
import { IpService } from './../common/services/ipservice.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
  constructor(private surservice: surveysService, private rewardservice: rewardsService, private router: Router, private activatedRoute: ActivatedRoute, private SeoService: seoservice, private ipService: IpService) { }
  surveyList: any[] = [];
  rewardList: any;
  redeemList: any[] = [];
  denominationList: any[] = [];
  showRewardsTable: boolean = false;
  showRedeemedTable: boolean = false;
  rewardData: any;
  amountDisplay: boolean = false;
  retailReward: any;
  restaurantReward: any;
  onlineReward: any;
  charitableReward: any;
  accntBalnsdollar: any;
  denomination: any = 0;
  totaldenomination: any[] = [];
  //accbalance: any;
  denominationcoins: any;
  filtertype: any;
  ngOnInit() {
    this.SeoService.setPageMetaData('Rewards | Redeem what you earn on the site - Conversant.pro', 'Your Coins may be redeemed for gift cards specifically curated for the needs of busy professionals or donated to a broad selection of charitable organizations.');
    this.getUserRewards();
    // this.accbalance = "50";//this.rewardList["accountBalance"];
    this.activatedRoute.queryParams.subscribe(params => {
      if (typeof params['filter'] != 'undefined') {
        this.filtertype = params['filter'];
        window.scroll(0, 550);
      }
    })
  };
  getSurveys() {
    this.surservice.GetSurveysList()
      .subscribe((result: any) => {
        this.surveyList = result;
      });
  };
  getUserRewards() {
    this.rewardservice.GetUserRewards()
      .subscribe((result: any) => {
          this.rewardList = result;

        this.accntBalnsdollar = this.rewardList.accountBalance / 750;
        //if (this.accntBalnsdollar >= 50) {
          do {
            this.denomination = this.denomination + 50;
        // this.denominationcoins = this.NumberWithCommas(this.denomination *750) + " Coins / $" + this.denomination;
            this.denominationcoins = formatNumber(Number(this.denomination * 750), 'en-US', '1.0-0') + " Coins / $" + this.denomination;
            let odenomination: Denomination = new Denomination();
            odenomination.title = this.denominationcoins;
            odenomination.amount = this.denomination;
            //this.denominationList.push(odenomination);
            this.totaldenomination.push(odenomination);
            if (this.accntBalnsdollar >= 50)
            this.accntBalnsdollar = this.accntBalnsdollar - 50;
          } while (this.accntBalnsdollar >= 50)
        this.accntBalnsdollar = this.rewardList.accountBalance / 750;
        //}
        this.GetRewardData(this.rewardList.accountBalance);
      });
    this.showRewardsTable = true;
    this.showRedeemedTable = false;
  };
//  NumberWithCommas(x) {
//    //x = x * 750;
//  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
//}
  getRedeemHistory() {
    this.rewardservice.GetRedeemHistory()
      .subscribe((result: any) => {
        this.redeemList = result;
      });
    this.showRedeemedTable = true;
    this.showRewardsTable = false;
  };
  GetRewardData(accBalns) {
    this.rewardservice.GetRewardData(accBalns)
      .subscribe((data) => {
        this.rewardData = data;
        this.retailReward = this.rewardData.filter(e => e.Category == 'Retail Gift Card');
        this.restaurantReward = this.rewardData.filter(e => e.Category == 'Restaurant Gift Card');
        this.onlineReward = this.rewardData.filter(e => e.Category == 'Online Services');
        this.charitableReward = this.rewardData.filter(e => e.Category == 'Charitable Donation');
      });
    if (accBalns < 20) {
      this.amountDisplay = false;
    } else {
      this.amountDisplay = true;
    }

  };
  rewardsClick(CatalougeGuid, data) {
    //alert(data);
    debugger;
    this.ipService.getIpAddress().subscribe((ipData: any) => {
      const ipAddress = ipData.ip;
      this.rewardservice.InserrtReward(data, CatalougeGuid,ipAddress).subscribe((data) => {
      });
    })
  }

  getPosition(event) {
    let offsetTop = 100;
    if (event.currentTarget.innerText == "Retail Gift Card") {
      offsetTop = 350;
    }
    else if (event.currentTarget.innerText == "Restaurant Gift Card") {
      offsetTop = 425;
    }
    else if (event.currentTarget.innerText == "Online Services") {
      offsetTop = 475;
    }
    else if (event.currentTarget.innerText == "Charitable Donation") {
      offsetTop = 525;
    }
    window.scroll(0, offsetTop);
  }

}

export class Denomination {
  title: string;
  amount: string;
}
